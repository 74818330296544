window.addEventListener('turbolinks:before-visit', function() { $('html, body').stop() });

// Add smooth scrolling to all links
$(document).on('click', 'a', function(event) {

  const currentUrl = window.location.href.split('#')[0]; 
  const destinationUrl = event.target.href && event.target.href.split('#')[0];

  // Make sure this.hash has a value before overriding default behavior
  // And we are on the same page
  if (this.hash !== "" && currentUrl === destinationUrl) {
    // Prevent default anchor click behavior
    event.preventDefault();

    // Store hash
    var hash = this.hash;

    // Using jQuery's animate() method to add smooth page scroll
    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
    $('html, body').animate({
      scrollTop: $(hash).offset().top
    }, 1100, function(){

      // Add hash (#) to URL when done scrolling (default click behavior)
      window.location.hash = hash;
    });
  } // End if
});