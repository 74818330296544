require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("site")
require("../site/notification")
const { default: message } = require("../site/message")

document.addEventListener('turbolinks:load', () => {
    message.init()
    window.grecaptcha = null
})