export default {
    init () {
      this.initFormSubmission()
    },
    initFormSubmission () {
      const formElements = document.querySelectorAll('form[data-form-message]')
      formElements.forEach(formElement => {
        formElement.addEventListener('submit', e => {
          e.preventDefault()

          window.recaptchaForm = e.target
          window.executeRecaptcha()
        })
      })
    }
  }
  
  const loadRecaptchaWithCallback = callback => {
    if (window.grecaptcha) {
      return false
    }
  
    const recaptchaScript = document.createElement('script')
    recaptchaScript.src = `https://www.google.com/recaptcha/api.js?onload=${callback}`
    document.body.appendChild(recaptchaScript)
    return true
  }
  
  window.loadRecaptcha = () => {
    loadRecaptchaWithCallback('onRecaptchaLoad')
  }
  
  window.executeRecaptcha = () => {
    if (!loadRecaptchaWithCallback('onRecaptchaLoadExecute')) {
      window.grecaptcha.execute()
    }
  }
  
  window.onRecaptchaLoad = () => {
    window.demoRecaptchaLoadCallback && window.demoRecaptchaLoadCallback()
  }
  window.onRecaptchaLoadExecute = () => {
    window.onRecaptchaLoad()
    window.grecaptcha.execute()
  }
  
  window.messageRecaptchaSuccessCallback = (token) => {
    const formData = new FormData(window.recaptchaForm)
    formData.append('g-recaptcha-response', token)
  
    let url = ''
    if ('formMessage' in window.recaptchaForm.dataset) {
      url = '/messages'
    } else {
      window.grecaptcha.reset()
      return
    }
  
    fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(() => window.grecaptcha.reset())
      .then(() => window.recaptchaForm.reset())
      .then(() => document.getElementById('form-success-notification').show(SUCCESS_NOTIFICATION_DURATION))
      .catch(() => {
        window.grecaptcha.reset()
        document.getElementById('form-fail-notification').show(FAIL_NOTIFICATION_DURATION)
      })
  }
  